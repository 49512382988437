const baseURL = 'https://crm.g-plus.app/api/actions'
const token = 'dMqe0-9_frFlZNr0ZvZ-kVaKB74VMSsFs2V9nNG9D2o'
const partner_id = '19278'

const pixelId = '839382151364099'

const accessToken =
  'EAASyfJY0DosBOz1KrRLEoqp8m32vYIu9sFhdkU72EJcTKwg2Soeu2NpsMs3PGcAxKIlJGXAnrZAvKH5DX26Fbu8npObmXTf9wN1aOW2ZCLaWMsr5QLFBxAAOjCc66YJ6X15B19UMy3NIs0M0aWZBrrWNWwQvWRI6rS8hrBdyBZCGIeE5FdeW7tWnbGRjEjHrhAZDZD'

export { baseURL, token, partner_id, pixelId, accessToken }
