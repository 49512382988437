import { create } from 'zustand'

const popUpStore = create((set) => ({
  isPopupOpen: false,
  handleClose: () => set({ isPopupOpen: false }),
  handleShow: () => set({ isPopupOpen: true }),
  togglePopUp: () => set((state) => ({ isPopupOpen: !state.isPopupOpen })),
}))

export { popUpStore }

// const isPopupOpen = popUpStore((state) => state.isPopupOpen)
// const handleClose = popUpStore((state) => state.handleClose)
// const handleShow = popUpStore((state) => state.handleShow)
// const togglePopUp = popUpStore((state) => state.togglePopUp)
