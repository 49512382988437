import React from 'react'
import './why.css'
import {
  WhyCard1,
  WhyCard10,
  WhyCard11,
  WhyCard12,
  WhyCard2,
  WhyCard3,
  WhyCard4,
  WhyCard5,
  WhyCard6,
  WhyCard7,
  WhyCard8,
  WhyCard9,
  WhyVideo,
} from '../../Assets/Images/WhySab'

const Why = () => {
  // Масив даних для карточок
  const cardData = [
    {
      id: 1,
      imgSrc: WhyCard1,
      title: 'Тип будівництва',
      description: 'монолітно-каркасний',
    },
    {
      id: 2,
      imgSrc: WhyCard2,
      title: 'Утеплення',
      description: 'пінополістирол 150 мм',
    },
    {
      id: 3,
      imgSrc: WhyCard3,
      title: 'Житлових секцій',
      description: '2 секції (під’їздів)',
    },
    {
      id: 4,
      imgSrc: WhyCard4,
      title: 'Клас',
      description: 'Сервіс клас (комфорт)',
    },
    {
      id: 5,
      imgSrc: WhyCard5,
      title: 'Поверховість',
      description: '8-7 поверхів',
    },
    {
      id: 6,
      imgSrc: WhyCard6,
      title: 'Кількість квартир',
      description: '108',
    },
    {
      id: 7,
      imgSrc: WhyCard7,
      title: 'Висота стелі',
      description: '2,7 метрів',
    },
    {
      id: 8,
      imgSrc: WhyCard8,
      title: 'Опалення',
      description: 'автономне',
    },
    {
      id: 9,
      imgSrc: WhyCard9,
      title: 'Закрита територія',
      description: 'від автомобілів',
    },
    {
      id: 10,
      imgSrc: WhyCard10,
      title: 'Паркінг',
      description: 'гостьовий',
    },
    {
      id: 11,
      imgSrc: WhyCard11,
      title: 'Безпека',
      description: 'укриття в кожному під’їзді',
    },
    {
      id: 12,
      imgSrc: WhyCard12,
      title: 'Магазин',
      description: 'великий супермаркет',
    },
  ]

  return (
    <div className="whysab_block" id={'why'}>
      <div className="customContainer">
        <div className="why_title_block">
          <div>
            <h2 className="why_title">Чому обирають </h2>
          </div>
          <div className="why_title_line"></div>
        </div>
        <h3 className="why_title_span">ЖК SAB Борислав?</h3>
        <div className="why_content_block">
          <div className="why_content_left">
            <p className="why_content_left_text">
              <span className="why_content_left_text__bold">Якість та надійність: </span> використовуємо тільки перевірені матеріали
              та сучасні технології будівництва, що гарантує довговічність та
              безпеку наших будинків. <br />
              <br />
              <span className="why_content_left_text__bold">Продумані планування: </span> пропонуємо різноманітні варіанти
              планувань квартир, щоб кожен зміг знайти ідеальний варіант для
              себе та своєї родини. <br />
              <br /> <span className="why_content_left_text__bold">Енергоефективність: </span> приділяємо особливу увагу
              енергозбереженню, що дозволяє мешканцям наших будинків економити
              на комунальних платежах. <br />
              <br /> <span className="why_content_left_text__bold">Розвинена інфраструктура: </span> обираємо для будівництва райони з
              розвиненою інфраструктурою, поруч з школами, дитячими садками,
              магазинами та зупинками громадського транспорту.
            </p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://drive.google.com/drive/folders/1otbg3X5ojzzJylKYJlVOw3yYb_nmDe4Q"
            >
              <button className="why_btn">Наша документація</button>
            </a>
          </div>
          <div>
            <video
              className="why_video_img"
              src={WhyVideo}
              autoPlay
              loop
              muted
            />
          </div>
        </div>

        <div className="why_cards_block">
          {cardData.map((card) => (
            <div key={card.id} className="why_card">
              <div className="why_card_top">
                <img
                  className="why_card_img"
                  src={card.imgSrc}
                  alt={`WhyCard${card.id}`}
                />
                <p className="why_card_top_title">{card.title}</p>
              </div>
              <div>{card.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Why
