import React, { useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import s from './SABBuilding.module.css'
import {
  gallery_arrow_left,
  gallery_arrow_right,
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image16,
  image17,
} from '../../Assets/Images/SabBuilding'

const SabBuilding = () => {
  const images = [
    image17,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image16,
    image1,
  ]

  const limitedDots = 16

  const [currentIndex, setCurrentIndex] = useState(0)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [fullscreenImg, setFullscreenImg] = useState(null)

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length)
  }

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length,
    )
  }

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    preventScrollOnSwipe: true,
    trackTouch: true,
    trackMouse: true,
  })

  const toggleFullscreen = (img = null) => {
    setFullscreenImg(img)
    setIsFullscreen((prev) => !prev)
  }

  const handleWidgetOpen = () => {
    if (window.g_widget_open) {
      window.g_widget_open(25711)
    } else {
      console.error('Функція g_widget_open не визначена.')
    }
  }

  return (
    <div className={`customContainer ${s.wrap}`} id={'sabBuilding'}>
      <div className={s.wrap_titleWrap}>
        <div className={s.wrap_titleWrap__box}>
          <hr className={s.wrap_titleWrap__box_line} />
          <h2 className={s.wrap_titleWrap__box_title}>ЖК SAB</h2>
        </div>
        <h2 className={s.wrap_titleWrap__subtitle}>Борислав</h2>
      </div>

      <div className={s.wrap_bottom}>
        <div {...swipeHandlers} className={s.wrap_bottom__imgWrap}>
          <div
            className={s.imageContainer}
            style={{
              transform: `translateX(-${currentIndex * 100}%)`,
            }}
          >
            {images.map((img, index) => (
              <img
                key={index}
                src={img}
                alt="house"
                className={s.wrap_bottom__img}
                onClick={() => toggleFullscreen(img)} // відкриває картинку в повний екран при кліку
              />
            ))}
          </div>

          <div className={s.controls}>
            <button onClick={handlePrev} className={s.control_btn}>
              <img
                src={gallery_arrow_left}
                alt="left arrow"
                className={s.arrow_icon}
              />
            </button>
            <button onClick={handleNext} className={s.control_btn}>
              <img
                src={gallery_arrow_right}
                alt="right arrow"
                className={s.arrow_icon}
              />
            </button>
          </div>

          <div className={s.dots}>
            {Array.from({ length: limitedDots }).map((_, index) => (
              <span
                key={index}
                className={`${s.dot} ${currentIndex === index ? s.active : ''}`}
                onClick={() => setCurrentIndex(index)}
              ></span>
            ))}
          </div>
        </div>

        {/* Збережено твій блок з текстом і кнопкою */}
        <div className={s.right}>
          <p className={s.right_textWrap}>
            <span>
              Перший ЖК у м. Борислав - це поєднання доступних цін, сучасних
              планувань та якісних матеріалів.
            </span>
            <span>
              Розміщення на вул. В. Великого створює комфортну інфраструкруту
              для мешканців - все необхідне поруч.
            </span>
            <span>
              Залишайтеся з рідними, будуйте плани та насолоджуйтесь життям у
              своєму місті разом з SAB.
            </span>
          </p>

          <button className={s.right_btn} onClick={handleWidgetOpen}>
            Обрати квартиру
          </button>
        </div>
      </div>

      {isFullscreen && (
        <div className={s.fullscreenOverlay} onClick={() => toggleFullscreen()}>
          <img
            src={fullscreenImg}
            className={s.fullscreenImg}
            alt="fullscreen"
          />
        </div>
      )}
    </div>
  )
}

export { SabBuilding }
