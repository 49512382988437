import React, { useState, useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Link } from 'react-scroll'

import style from './MobHeader.module.css'
import { ReactComponent as CloseIcon } from '../../../../Assets/Icons/closeIcon.svg'
import { burgerIcon, logo } from '../../../../Assets/Icons/icons'
// import { popUpStore } from '../../../Logos/popUpStore'

const MobHeader = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false) // Додано стан для прокрутки
  // const togglePopUp = popUpStore((state) => state.togglePopUp)

  const burgerVars = {
    initial: {
      scaleY: 0,
      opacity: 0,
    },
    animate: {
      scaleY: 1,
      opacity: 1,
      transition: {
        duration: 0.4,
        ease: [0.12, 0, 0.39, 0],
      },
    },
    exit: {
      scaleY: 0,
      opacity: 0,
      transition: {
        duration: 0.2,
        ease: [0.12, 0, 0.39, 0],
      },
    },
  }

  const toggleMenu = () => {
    setIsOpenMenu(!isOpenMenu)
  }

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0) // Змінює стан при прокрутці
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleWidgetOpen = () => {
    if (window.g_widget_open) {
      window.g_widget_open(25711) // Викликаємо функцію window.g_widget_open з параметром 123456
    } else {
      console.error('Функція g_widget_open не визначена.')
    }
  }
  return (
    <header>
      <div
        className={`customContainer ${style.container} ${
          isScrolled ? style.scrolled : ''
        }`}
      >
        <Link to={'start'} className={style.container_logo}>
          <img src={logo} alt="logo" className={style.container_logo} />
        </Link>
        <img
          src={burgerIcon}
          alt="burgerIcon"
          className={style.container_burgerIcon}
          onClick={toggleMenu}
        />
      </div>

      <AnimatePresence>
        {isOpenMenu && (
          <motion.div
            variants={burgerVars}
            initial={'initial'}
            animate={'animate'}
            exit={'exit'}
            className={style.burger}
            onClick={toggleMenu}
          >
            <div>
              <div className={style.burger_top}>
                <span className={style.burger_top__txt}>Меню</span>
                <span className={style.burger_top__btn}>
                  <CloseIcon onClick={toggleMenu} />
                </span>
              </div>

              <div className={style.burger_middle}>
                <Link
                  to={'sabInvestment'}
                  smooth={true}
                  duration={300}
                  onClick={toggleMenu}
                  className={style.burger_middle__link}
                >
                  Про нас
                </Link>
                <Link
                  to={'why'}
                  smooth={true}
                  duration={300}
                  onClick={toggleMenu}
                  className={style.burger_middle__link}
                >
                  Чому ми
                </Link>
                <Link
                  to={'cases'}
                  smooth={true}
                  duration={300}
                  onClick={toggleMenu}
                  className={style.burger_middle__link}
                >
                  Проекти
                </Link>
                <Link
                  to={'footer'}
                  smooth={true}
                  duration={300}
                  onClick={toggleMenu}
                  className={style.burger_middle__link}
                >
                  Контакти
                </Link>
                <a
                  href="tel:+380 50 077 07 10"
                  className={style.burger_middle__link}
                >
                  +380 50 077 07 10
                </a>
                <button
                  className={style.burger_middle__btn}
                  onClick={() => {
                    toggleMenu()
                    handleWidgetOpen()
                  }}
                >
                  Обрати квартиру
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  )
}

export { MobHeader }
