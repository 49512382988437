import { useEffect } from 'react'
import axios from 'axios'
import CryptoJS from 'crypto-js'

// Функція для хешування даних SHA-256
const hashData = (data) =>
  data ? CryptoJS.SHA256(data).toString(CryptoJS.enc.Hex) : null

// Функція для отримання значення з cookies
const getCookie = (name) => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
  return match ? decodeURIComponent(match[2]) : null
}

// Функція для отримання даних користувача з cookies або localStorage
const getUserData = () => {
  const email = localStorage.getItem('userEmail') || getCookie('userEmail')
  const phone = localStorage.getItem('userPhone') || getCookie('userPhone')
  const firstName =
    localStorage.getItem('userFirstName') || getCookie('userFirstName')
  const lastName =
    localStorage.getItem('userLastName') || getCookie('userLastName')
  const city = localStorage.getItem('userCity') || getCookie('userCity')
  const state = localStorage.getItem('userState') || getCookie('userState')
  const zip = localStorage.getItem('userZip') || getCookie('userZip')
  const country =
    localStorage.getItem('userCountry') || getCookie('userCountry')
  const externalId = localStorage.getItem('userId') || getCookie('userId')
  const ipAddress = localStorage.getItem('userIP') || getCookie('userIP')
  const gender = localStorage.getItem('userGender') || getCookie('userGender')
  const dob = localStorage.getItem('userDob') || getCookie('userDob')
  const clientUserAgent = navigator.userAgent || null
  const subscriptionId =
    localStorage.getItem('subscriptionId') || getCookie('subscriptionId')

  return {
    em: hashData(email),
    ph: hashData(phone),
    fn: hashData(firstName),
    ln: hashData(lastName),
    ct: hashData(city),
    st: hashData(state),
    zp: hashData(zip),
    country: hashData(country),
    external_id: hashData(externalId),
    client_ip_address: hashData(ipAddress),
    ge: gender ? hashData(gender.toLowerCase().charAt(0)) : null,
    dob: dob ? hashData(dob.replace(/-/g, '')) : null,
    client_user_agent: clientUserAgent,
    subscription_id: hashData(subscriptionId),
  }
}

// Функція для надсилання події до Facebook Conversions API
const sendEventToFacebook = async (pixelId, accessToken, eventName) => {
  try {
    const userData = getUserData()

    const url = `https://graph.facebook.com/v12.0/${pixelId}/events?access_token=${accessToken}`

    const data = {
      data: [
        {
          event_name: eventName,
          event_time: Math.floor(Date.now() / 1000),
          action_source: 'website',
          user_data: userData,
        },
      ],
    }

    const response = await axios.post(url, data)
    console.log('Event sent to Facebook:', response.data)
  } catch (error) {
    console.error('Error sending event to Facebook:', error)
  }
}

// Основна компонента, що відправляє події до Facebook Conversions API
const FacebookConversionsAPI = ({ pixelId, accessToken }) => {
  useEffect(() => {
    sendEventToFacebook(pixelId, accessToken, 'PageView')
  }, [pixelId, accessToken])

  // const handleButtonClick = () => {
  //   sendEventToFacebook(pixelId, accessToken, 'Lead')
  // }

  return null
}

export default FacebookConversionsAPI
