import React from 'react'
import './sab_kreativ.css'
import { KreativLogo, SabLogo, XLogo } from '../../Assets/Images/SabKreativ'

const SabKreativ = () => {
  return (
    <div className="sab_kreativ_block">
      <div className="customContainer sab_kreativ_content">
        <div className="sab_kreativ_logos_block">
          <div>
            <img className="sab_kreativ_sablogo" src={SabLogo} alt="SabLogo" />
          </div>
          <div>
            <img className="sab_kreativ_xlogo" src={XLogo} alt="XLogo" />
          </div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://kreativ.group/"
          >
            <div>
              <img
                className="sab_kreativ_kreativlogo"
                src={KreativLogo}
                alt="KreativLogo"
              />
            </div>
          </a>
        </div>
        <div>
          <p className="sab_kreativ_text">
            SAB Investment company та KREATIV об’єднали зусилля задля того, щоб
            реалізувати ваші мрії про сучасний та затишний житловий простір, де
            ви зможете жити, не покидаючи рідний Борислав.
          </p>
        </div>
      </div>
    </div>
  )
}

export default SabKreativ
