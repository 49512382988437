import React from 'react'
import './sabinvest.css'
import { SabInvest } from '../../Assets/Images/SabInvest'

const SabInvestment = () => {
  return (
    <div className="sabinvest_block" id={'sabInvestment'}>
      <div className="customContainer">
        <div className="why_title_block">
          <div>
            <h2 className="why_title">SAB Investment</h2>
          </div>
          <div className="why_title_line"></div>
        </div>
        <div className="sabinvest_content">
          <div className="sabinvest_content_left">
            <div>
              <p className="sabinvest_content_text">
                САБ - це компанія, що інвестує у майбутнє України, створюючи
                сучасне та комфортне житло в містах Львівської обл. Ми віримо,
                що кожен заслуговує на дім своєї мрії, не покидаючи рідне місто.
              </p>
            </div>
            <div>
              <p className="sabinvest_content_text">
                Новий проект у Бориславі - це результат нашого прагнення зробити
                вагомий внесок у розвиток регіону. Ми будуємо для вас і з вами.
              </p>
            </div>
            <div>
              <p className="sabinvest_content_text">
                Ми розуміємо відповідальність, яку покладають на нас сотні
                сімей, довіряючи нам вибір майбутнього дому. Наша місія -
                збудувати для них комфортний мікросвіт, де кожен зможе сказати:
                "Добре бути вдома".
              </p>
            </div>
          </div>
          <div className="sabinvest_content_right">
            <img
              className="sabinvest_content_img"
              src={SabInvest}
              alt="SabInvest"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SabInvestment
