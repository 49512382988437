import React from 'react'
import style from './Cases.module.css'
import { useWindowSize } from '../../Hooks'

const Cases = () => {
  const { width } = useWindowSize()
  return (
    <div className={`customContainer ${style.wrap}`} id={'cases'}>
      <div className={style.wrap_titleWrap}>
        <hr className={style.wrap_titleWrap__line} />
        <h1 className={style.wrap_titleWrap__title}>Наші роботи</h1>
        <hr className={style.wrap_titleWrap__line} />
      </div>

      <div className={style.wrap_boxes}>
        <div className={`${style.box} ${style.first}`}>
          <div className={style.box_titleWrap}>
            <h6 className={style.box_title}>Електроконтакт Україна</h6>
            <span className={style.box_subtitle}>2017</span>
          </div>
          {/*<a*/}
          {/*  href="http://eku.com.ua/"*/}
          {/*  target="_blank"*/}
          {/*  rel="noopener noreferrer"*/}
          {/*>*/}
          {/*  <button className={style.box_btn}>Детальніше</button>*/}
          {/*</a>*/}
        </div>

        <div className={`${style.box} ${style.second}`}>
          <div className={style.box_titleWrap}>
            <h6 className={style.box_title}>РЕМ Самбір</h6>
            <span className={style.box_subtitle}>2022</span>
          </div>
          {/*<a*/}
          {/*  href="https://takbud.com.ua/offer/rem/"*/}
          {/*  target="_blank"*/}
          {/*  rel="noopener noreferrer"*/}
          {/*>*/}
          {/*  <button className={style.box_btn}>Детальніше</button>*/}
          {/*</a>*/}
        </div>

        <div className={`${style.box} ${style.third}`}>
          <div className={style.box_titleWrap}>
            <h6 className={style.box_title}>
              ЖК Avalon Yard {width > 720 ? '' : <br />} м. Львів
            </h6>
            <span className={style.box_subtitle}>2019</span>
          </div>
          {/*<a*/}
          {/*  href="https://avalon.com.ua/projects/avalon-yard/"*/}
          {/*  target="_blank"*/}
          {/*  rel="noopener noreferrer"*/}
          {/*>*/}
          {/*  <button className={style.box_btn}>Детальніше</button>*/}
          {/*</a>*/}
        </div>

        <div className={`${style.box} ${style.fourth}`}>
          <div className={style.box_titleWrap}>
            <h6 className={style.box_title}>
              ТРЦ "Вектор" {width > 720 ? '' : <br />}
              (Трускавець)
            </h6>
            <span className={style.box_subtitle}>2023</span>
          </div>
          {/*<a*/}
          {/*  href="https://www.instagram.com/vector.truskavets/"*/}
          {/*  target="_blank"*/}
          {/*  rel="noopener noreferrer"*/}
          {/*>*/}
          {/*  <button className={style.box_btn}>Детальніше</button>*/}
          {/*</a>*/}
        </div>
      </div>
    </div>
  )
}

export { Cases }
