import { baseURL, token, partner_id } from './tgUrls'

const sendInTg = async (msg) => {
  const formData = new FormData()
  formData.append('token', token)
  formData.append('action', 'partner-custom-form')
  formData.append('partner_id', partner_id)
  formData.append('name', msg.name)
  formData.append('phone', msg.phone)
  formData.append('lang', 'ua')
  formData.append('note', 'Запит на сайті з форми зворотного дзвінка')
  return await fetch(baseURL, {
    method: 'POST',
    body: formData,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      console.log(response)
      return response
    })
    .catch((error) => {
      console.error('Помилка при виконанні запиту:', error)
    })
}
export { sendInTg }
